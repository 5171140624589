import { Button } from "../Parts/Button";
import { Card } from "../Parts/Card";
import { Dialog } from "../Parts/Dialog";
import { Icon } from "../Parts/Icon";
import styles from "./styles.module.scss";
import dayjs from "dayjs";

export type ClientCertificateVerifyStartDialogProps = {
  onCancel: () => void;
  onConfirm: () => void;
};

export const ClientCertificateVerifyStartDialog: React.FC<
  ClientCertificateVerifyStartDialogProps
> = ({ onCancel, onConfirm }) => {
  const targetDay = dayjs("2025-07-01");
  const today = dayjs();
  const diffDays = targetDay.diff(today, "day");
  const formattedDate = targetDay.format("YYYY年M月D日");
  return (
    <Dialog>
      <Card>
        <div className={styles.inner}>
          <Icon size="medium" type="warning" color="descrutive" />
          <p className={styles.caution}>重要なお知らせ</p>
        </div>
        <div className={styles.titleBox}>
          <p className={styles.title}>
            クライアント証明書のインストールは
            <br />
            お済みですか？
          </p>
          <div className={styles.titleInner}>
            <p className={styles.titleInnerText}>
              <span className={styles.ato}>あと</span>
              <span className={styles.day}>{diffDays}</span>
              <span className={styles.niti}>日</span>
            </p>
          </div>
        </div>
      </Card>

      <Card>
        <p className={styles.text}>
          クライアント証明書をインストールしていない端末では、 <br />
          <span className={styles.textDay}>{formattedDate} 以降</span>
          Pocket Musubiをご利用できなくなります。
        </p>
        <p className={styles.subText}>
          インストール済みかどうかを必ず事前に👇チェックしてください。
        </p>
        <div className={styles.buttonContainer}>
          <Button
            onClick={onCancel}
            theme="secondary"
            minWidth="120px"
            minHeight="40px"
          >
            後でする
          </Button>
          <Button
            onClick={onConfirm}
            theme="primary"
            minWidth="305px"
            minHeight="40px"
          >
            インストール状況をチェックする
          </Button>
        </div>
      </Card>
    </Dialog>
  );
};
