import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { LoginPage } from "./pages/LoginPage";
import { QRcodeRegistrationCompletedPage } from "./pages/QRcodeRegistrationCompletedPage";
import { PatientsPage } from "./pages/PatientsPage";
import { QRCodePage } from "./pages/QRcodePage";
import { PharmaciesPage } from "./pages/PharmaciesPage";
import { NotFoundPage } from "./pages/ErrorPages/NotFoundPage";
import { InternalServerErrorPage } from "./pages/ErrorPages/InternalServerErrorPage";
import { configureV1 } from "./config/cognito.config";
import Amplify from "@aws-amplify/core";
import { AuthProvider } from "./service/auth/AuthProvider";
import { Authenticator } from "./components/Authenticator";
import { QRcodeRegistrationErrorPage } from "./pages/QRcodeRegistrationErrorPage";
import { PharmacyContextProvider } from "./hooks/usePharmacy";
import { PatientsContextProvider } from "./hooks/usePatients";
import { PatientContextProvider } from "./hooks/usePatient";
import { RawPrescriptionContextProvider } from "./hooks/useRawPrescription";
import { useEffect } from "react";
import { initializeGA, useTracking } from "./service/ga";
import { QRcodeErrorPage } from "./pages/QRCodeErrorPage";
import { MaintenanceProvider } from "./service/maintainance";
import { ModalProviderContainer } from "./hooks/useModal";

// 初期化時に指定が必要なのでconfigureV1を取り敢えず設定(auth側でconfigureの再設定している)
Amplify.configure(configureV1);

function App() {
  // Google Analytics Tracking
  useEffect(() => {
    initializeGA(process.env.REACT_APP_GA_ID);
  }, [process.env.REACT_APP_GA_ID]);
  useTracking(process.env.REACT_APP_GA_ID);

  return (
    <MaintenanceProvider>
      <Router>
        <ModalProviderContainer>
          <PharmacyContextProvider>
            <AuthProvider>
              <PatientsContextProvider>
                <PatientContextProvider>
                  <RawPrescriptionContextProvider>
                    <Switch>
                      <Route exact path="/home">
                        <Authenticator component={HomePage} pageProps />
                      </Route>
                      <Route exact path="/qrcode-error">
                        <QRcodeErrorPage />
                      </Route>
                      <Route exact path="/qrcode">
                        <Authenticator component={QRCodePage} pageProps />
                      </Route>
                      <Route exact path="/patients">
                        <Authenticator component={PatientsPage} pageProps />
                      </Route>
                      <Route exact path="/pharmacies">
                        <Authenticator component={PharmaciesPage} pageProps />
                      </Route>
                      <Route exact path="/qrcode-registration-completed">
                        <Authenticator
                          component={QRcodeRegistrationCompletedPage}
                          pageProps
                        />
                      </Route>
                      <Route exact path="/qrcode-registration-error">
                        <Authenticator
                          component={QRcodeRegistrationErrorPage}
                          pageProps
                        />
                      </Route>
                      <Route exact path="/">
                        <LoginPage />
                      </Route>
                      <Route
                        path="/error-500"
                        component={InternalServerErrorPage}
                      />
                      <Route component={NotFoundPage} />
                    </Switch>
                  </RawPrescriptionContextProvider>
                </PatientContextProvider>
              </PatientsContextProvider>
            </AuthProvider>
          </PharmacyContextProvider>
        </ModalProviderContainer>
      </Router>
    </MaintenanceProvider>
  );
}

export default App;
