import { Button } from "../Parts/Button";
import { Card } from "../Parts/Card";
import { Dialog } from "../Parts/Dialog";
import styles from "./styles.module.scss";

export type ClientCertificateVerifySuccessDialogProps = {
  onClose: () => void;
};

export const ClientCertificateVerifySuccessDialog: React.FC<
  ClientCertificateVerifySuccessDialogProps
> = ({ onClose }) => {
  return (
    <Dialog>
      <Card>
        <div className={styles.inner}>
          <p className={styles.title}>
            {"\u{1F389}ご対応ありがとうございます！"}
          </p>
          <p className={styles.description}>
            本端末にクライアント証明書がインストールされていることを確認できました。
          </p>
          <p className={styles.note}>
            <span>2025年7月1日以降</span>
            はアクセスする度に証明書の選択画面が表示されます。お手数ですが都度選択をお願いします。
          </p>
          <div className={styles.buttonContainer}>
            <Button onClick={onClose} theme="primary" minWidth="120px">
              閉じる
            </Button>
          </div>
        </div>
      </Card>
    </Dialog>
  );
};
