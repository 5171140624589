import React from "react";
import { useModalDispatch } from "../../hooks/useModal";
import {
  ClientCertificateVerifyDialogProps,
  HandleClientCertificateVerifyDialog,
} from "../ClientCertificateVerifyDialogs/HandleClientCertificateVerifyDialog";

export const useClientCertificateVerifyDialog = () => {
  const { showModal, hideModal } = useModalDispatch();

  const showClientCertificateVerifyDialog = React.useCallback(
    (props: ClientCertificateVerifyDialogProps) => {
      showModal({
        HandleClientCertificateVerifyDialog: (
          <HandleClientCertificateVerifyDialog {...props} />
        ),
      });
    },
    [showModal]
  );

  const hideClientCertificateVerifyDialog = React.useCallback(() => {
    hideModal("HandleClientCertificateVerifyDialog");
  }, [hideModal]);

  return {
    showClientCertificateVerifyDialog,
    hideClientCertificateVerifyDialog,
  };
};
