import { useCallback, useState } from "react";
import { ClientCertificateVerifyDescriptionDialog } from "./ClientCertificateVerifyDescriptionDialog";
import { ClientCertificateVerifyErrorDialog } from "./ClientCertificateVerifyErrorDialog";
import { ClientCertificateVerifyFailedDialog } from "./ClientCertificateVerifyFailedDialog";
import { ClientCertificateVerifyStartDialog } from "./ClientCertificateVerifyStartDialog";
import { ClientCertificateVerifySuccessDialog } from "./ClientCertificateVerifySuccessDialog";
import type { ALL_DIALOG_TYPES, DialogProps, DialogType } from "./DialogProps";

type Props<DialogTypes extends readonly DialogType[]> = {
  types: DialogTypes;
};

export const useClientCertificateVerifyDialog = <
  DialogTypes extends readonly DialogType[]
>(
  props: Props<DialogTypes>
) => {
  const [dialogState, setDialogState] = useState<DialogType>(props.types[0]);

  const renderDialog = useCallback(
    (dialogProps: DialogProps<Writeable<DialogTypes>>) => {
      const {
        clientCertificateVerifyStartDialogProps,
        clientCertificateVerifyDescriptionDialogProps,
        clientCertificateVerifySuccessDialogProps,
        clientCertificateVerifyFailedDialogProps,
        clientCertificateVerifyErrorDialogProps,
      } = dialogProps as DialogProps<Writeable<typeof ALL_DIALOG_TYPES>>;

      return (
        <>
          {dialogState === "START" && (
            <ClientCertificateVerifyStartDialog
              {...clientCertificateVerifyStartDialogProps}
            />
          )}
          {dialogState === "DESCRIPTION" && (
            <ClientCertificateVerifyDescriptionDialog
              {...clientCertificateVerifyDescriptionDialogProps}
            />
          )}
          {dialogState === "SUCCESS" && (
            <ClientCertificateVerifySuccessDialog
              {...clientCertificateVerifySuccessDialogProps}
            />
          )}
          {dialogState === "FAILED" && (
            <ClientCertificateVerifyFailedDialog
              {...clientCertificateVerifyFailedDialogProps}
            />
          )}
          {dialogState === "ERROR" && (
            <ClientCertificateVerifyErrorDialog
              {...clientCertificateVerifyErrorDialogProps}
            />
          )}
        </>
      );
    },
    [dialogState]
  );

  // 説明画面への遷移.
  const moveToDescriptionDialog = useCallback(() => {
    setDialogState("DESCRIPTION");
  }, []);

  // スタート画面に戻る.
  const backToStartDialog = useCallback(() => {
    setDialogState("START");
  }, []);

  // 成功画面への遷移.
  const moveToSuccessDialog = useCallback(() => {
    setDialogState("SUCCESS");
  }, []);

  // 失敗画面への遷移.
  const moveToFailedDialog = useCallback(() => {
    setDialogState("FAILED");
  }, []);

  // エラー画面への遷移.
  const moveToErrorDialog = useCallback(() => {
    setDialogState("ERROR");
  }, []);

  return {
    renderDialog,
    moveToDescriptionDialog,
    backToStartDialog,
    moveToSuccessDialog,
    moveToFailedDialog,
    moveToErrorDialog,
    dialogState,
  };
};
