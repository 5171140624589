import { datadogLogs } from "@datadog/browser-logs";
import useLoadingOverlay from "../../hooks/useLoadingOverlay";
import { KEYS, setItem } from "../../service/localStorage";
import { useClientCertificateVerifyDialog } from "./useClientCertificateVerifyDialog";

export type ClientCertificateVerifyDialogProps = {
  onCancel: () => void;
  onConfirm: () => void;
  onClose: () => void;
};

export const HandleClientCertificateVerifyDialog = (
  props: ClientCertificateVerifyDialogProps
) => {
  const { showLoadingOverlay, closeLoadingOverlay } = useLoadingOverlay();
  const {
    renderDialog,
    moveToDescriptionDialog,
    backToStartDialog,
    moveToSuccessDialog,
    moveToFailedDialog,
    moveToErrorDialog,
  } = useClientCertificateVerifyDialog<
    ["START", "DESCRIPTION", "SUCCESS", "FAILED", "ERROR"]
  >({
    types: ["START", "DESCRIPTION", "SUCCESS", "FAILED", "ERROR"],
  });

  const startCertificateVerification = async () => {
    try {
      showLoadingOverlay();
      const response = await fetch(
        `${process.env.REACT_APP_ISHIZUE_API_URL}verifyCertificate`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status === 200) {
        setItem(KEYS.CERTIFICATE_VERIFIED, "true");
        moveToSuccessDialog();
        return;
      }

      if (response.status === 403) {
        datadogLogs.logger.warn("クライアント証明書検証が失敗", {
          action: "client_certificate_verification",
          statusCode: response.status,
        });
        moveToFailedDialog();
        return;
      }

      datadogLogs.logger.error("クライアント証明書検証でエラーが発生", {
        action: "client_certificate_verification",
        statusCode: response.status,
      });
      moveToErrorDialog();
    } catch (error) {
      // ネットワークエラーの場合はFailedDialogに移動
      // 本来のエラーはERR_CONNECTION_RESET,ERR_SOCKET_NOT_CONNECTEDだが、ブラウザ側で隠蔽される
      // そのため、Fetch APIのクライアントエラーをチェックする
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        moveToFailedDialog();
        datadogLogs.logger.warn("クライアント証明書検証が失敗", {
          action: "client_certificate_verification",
          error: error,
        });
      } else {
        moveToErrorDialog();
        datadogLogs.logger.error("クライアント証明書検証で例外が発生", {
          action: "client_certificate_verification",
          error: error instanceof Error ? error.message : "Unknown error",
        });
      }
    } finally {
      closeLoadingOverlay();
    }
  };

  const handleStartDialogConfirm = () => {
    moveToDescriptionDialog();
  };

  return (
    <div>
      {renderDialog({
        clientCertificateVerifyStartDialogProps: {
          onCancel: props.onCancel,
          onConfirm: handleStartDialogConfirm,
        },
        clientCertificateVerifyDescriptionDialogProps: {
          onCancel: backToStartDialog,
          onVerify: startCertificateVerification,
        },
        clientCertificateVerifySuccessDialogProps: {
          onClose: props.onClose,
        },
        clientCertificateVerifyFailedDialogProps: {
          onClose: props.onClose,
        },
        clientCertificateVerifyErrorDialogProps: {
          onClose: props.onClose,
        },
      })}
    </div>
  );
};
