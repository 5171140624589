import { Icon, iconType } from "../Icon";
import styles from "./styles.module.scss";

type Props = {
  onClick: () => void;
  children: React.ReactNode;
  theme?: "primary" | "secondary";
  minWidth?: string;
  minHeight?: string;
  iconFront?: iconType;
  iconBack?: iconType;
};

export const Button: React.VFC<Props> = ({
  onClick,
  children,
  theme = "primary",
  minWidth,
  minHeight,
  iconFront,
  iconBack,
}) => {
  return (
    <button
      className={`${styles.button} ${styles[theme]}`}
      onClick={onClick}
      style={{ minWidth: minWidth, minHeight: minHeight }}
    >
      {iconFront && (
        <Icon
          size="small"
          type={iconFront}
          color={theme === "primary" ? "white" : "primary"}
        />
      )}
      {children}
      {iconBack && (
        <Icon
          size="small"
          type={iconBack}
          color={theme === "primary" ? "white" : "primary"}
        />
      )}
    </button>
  );
};
