import { LoadingRing } from "../components/LoadingRing";
import { useModalDispatch } from "./useModal";

export default function useLoadingOverlay() {
  const { showModal, hideModal } = useModalDispatch();

  const showLoadingOverlay = () => {
    showModal({ Loader: <LoadingRing /> });
  };

  const closeLoadingOverlay = () => {
    hideModal("Loader");
  };

  return {
    showLoadingOverlay,
    closeLoadingOverlay,
  };
}
