import { Button } from "../Parts/Button";
import { Card } from "../Parts/Card";
import { Dialog } from "../Parts/Dialog";
import styles from "./styles.module.scss";

import chirattoCat from "../../../assets/images/common/chiratto_cat.svg";

export type ClientCertificateVerifyFailedDialogProps = {
  onClose: () => void;
};

export const ClientCertificateVerifyFailedDialog: React.FC<
  ClientCertificateVerifyFailedDialogProps
> = ({ onClose }) => {
  return (
    <Dialog>
      <Card isBottomPadding="none">
        <div className={styles.headInner}>
          <p className={styles.title}>{"\u{1F62D} 準備がまだのようです"}</p>
        </div>
      </Card>
      <Card>
        <div className={styles.bodyInner}>
          <ul className={styles.note}>
            <li>
              「kakehashi-」以外の証明書を間違って選択した場合：
              <br />
              <span className={styles.importantText}>必ず端末を再起動</span>
              して再度お試しください。
            </li>
            <li>
              「kakehashi-」の証明書が表示されなかった場合：
              <br />
              カケハシからの案内を確認してクライアント証明書をインストールしてください。
            </li>
          </ul>
          <div className={styles.buttonContainer}>
            <Button onClick={onClose} theme="primary" minWidth="120px">
              閉じる
            </Button>
          </div>
        </div>
      </Card>
      <img className={styles.catImage} src={chirattoCat} alt="ChirattoCat" />
    </Dialog>
  );
};
