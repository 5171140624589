import type { FC } from "react";
import { ReactComponent as OpennewIcon } from "../../../../assets/images/common/opennew.svg";
import { ReactComponent as WarningIcon } from "../../../../assets/images/common/warning.svg";
import { ReactComponent as ChevronLeftIcon } from "../../../../assets/images/common/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "../../../../assets/images/common/chevron-right.svg";

const sizeToPx = {
  huge: 44,
  extraLarge: 32,
  large: 28,
  medium: 24,
  small: 20,
  extraSmall: 16,
} as const;

type Size = keyof typeof sizeToPx;

const iconComponents = {
  opennew: OpennewIcon,
  warning: WarningIcon,
  chevronLeft: ChevronLeftIcon,
  chevronRight: ChevronRightIcon,
} as const;

const iconColors = {
  // primary: "#00796b",
  primary: "#17a1e6",
  white: "#ffffff",
  descrutive: "#f44336",
} as const;

export type iconType = keyof typeof iconComponents;
export type colorType = keyof typeof iconColors;

export type Properties = {
  type: iconType;
  size?: Size;
  color?: colorType;
};

export const Icon: FC<Properties> = ({
  type,
  size = "medium",
  color = "primary",
}) => {
  const IconComponent = iconComponents[type];
  const iconSize = sizeToPx[size];
  const iconColor = iconColors[color];

  return (
    <IconComponent
      width={iconSize}
      height={iconSize}
      fill={iconColor}
      style={{ display: "block" }}
    />
  );
};
