import { Button } from "../Parts/Button";
import { Card } from "../Parts/Card";
import { Dialog } from "../Parts/Dialog";
import { Icon } from "../Parts/Icon";
import styles from "./styles.module.scss";

export type ClientCertificateVerifyDescriptionDialogProps = {
  onCancel: () => void;
  onVerify: () => void;
};

export const ClientCertificateVerifyDescriptionDialog: React.FC<
  ClientCertificateVerifyDescriptionDialogProps
> = ({ onCancel, onVerify }) => {
  return (
    <Dialog>
      <Card>
        <div className={styles.inner}>
          <Icon size="medium" type="warning" color="descrutive" />
          <p className={styles.caution}>
            次の手順に従ってチェックを行ってください
          </p>
        </div>
      </Card>
      <Card>
        <div className={styles.textBox}>
          <ol className={styles.list}>
            <li className={styles.textFirst}>[検証開始]をクリック</li>
            <li className={styles.textFirst}>証明書選択画面が表示される</li>
            <li>
              <ol>
                <li className={styles.textSecond}>
                  「kakehashi-」から始まる証明書が表示された場合はその証明書を選んで[OK]をクリック
                </li>
                <li className={styles.textSecond}>
                  「kakehashi-」から始まる証明書が表示されない場合は[キャンセル]をクリック
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            onClick={onCancel}
            theme="secondary"
            minWidth="120px"
            minHeight="40px"
            iconFront="chevronLeft"
          >
            戻る
          </Button>
          <Button
            onClick={onVerify}
            theme="primary"
            minWidth="125px"
            minHeight="40px"
            iconBack="opennew"
          >
            検証開始
          </Button>
        </div>
      </Card>
    </Dialog>
  );
};
