import styles from "./styles.module.scss";

type Props = {
  children: React.ReactNode;
  isBottomPadding?: "none" | "default";
};

export const Card: React.FC<Props> = ({
  children,
  isBottomPadding = "default",
}) => {
  const paddingClassName =
    isBottomPadding === "none"
      ? styles.bottomPaddingNone
      : styles.bottomPaddingDefault;

  return <div className={`${styles.card} ${paddingClassName}`}>{children}</div>;
};
