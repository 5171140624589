import { useHistory } from "react-router-dom";
import React, { useCallback, useContext, useEffect } from "react";
import { Modal } from "../components/Modal";

// より厳密な型定義
type ModalKey = string;
type Content = Record<ModalKey, React.ReactNode>;

type ModalContextValue = {
  showModal: (content: Partial<Content>, hideOnClickOverlay?: boolean) => void;
  hideModal: (key?: ModalKey) => void;
};

// 不要な際描画を防ぐために Context を二つに分けている YO
// https://zenn.dev/takepepe/articles/context-custom-hooks
const ModalDispatchContext = React.createContext<ModalContextValue>({
  showModal: () => {
    console.warn("ModalProvider が設定されていません");
  },
  hideModal: () => {
    console.warn("ModalProvider が設定されていません");
  },
});

const ModalStateContext = React.createContext<{
  showingModal: boolean;
}>({
  showingModal: false,
});

export const ModalProviderContainer: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const { showingModal, content, hideOnClickOverlay, showModal, hideModal } =
    useModalCore();

  const history = useHistory();

  useEffect(() => {
    const unMount = history.listen(() => {
      hideModal();
    });
    return () => {
      unMount();
    };
  }, [history, hideModal]);

  return (
    <ModalStateContext.Provider value={{ showingModal }}>
      <ModalDispatchContext.Provider value={{ showModal, hideModal }}>
        {props.children}
        {showingModal && (
          <Modal
            showModal={showingModal}
            onClose={
              hideOnClickOverlay
                ? () => {
                    hideModal();
                  }
                : () => {
                    return;
                  }
            }
            contents={Object.keys(content).map((key) => {
              return <React.Fragment key={key}>{content[key]}</React.Fragment>;
            })}
          />
        )}
      </ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
};

function useModalCore() {
  const [showingModal, setModal] = React.useState(false);
  const [content, setContent] = React.useState<Content>({});
  const [hideOnClickOverlay, setHideOnClickOverlay] = React.useState(false);

  useEffect(() => {
    // contentが空になった時のみモーダルを閉じる
    if (Object.keys(content).length === 0 && showingModal) {
      setModal(false);
    }
  }, [content, showingModal, setModal]);

  const showModal = useCallback(
    (_content: Partial<Content>, _hideOnClickOverlay?: boolean) => {
      try {
        setContent((prev) => ({ ...prev, ..._content }));
        setHideOnClickOverlay(_hideOnClickOverlay ?? false);
        setModal(true);
      } catch (error) {
        console.error("モーダルの表示に失敗しました:", error);
      }
    },
    [setModal, setContent, setHideOnClickOverlay]
  );

  const hideModal = useCallback(
    (key?: ModalKey) => {
      // keyがない場合は全てのモーダルを閉じる
      if (!key) {
        setModal(false);
        setContent({});
        return;
      }

      setContent((prev) => {
        // keyがcontentに存在しない場合警告を出す
        if (!prev[key]) {
          console.warn(`"${key}"は表示されていません`);
          return prev;
        }
        const newContent = { ...prev };
        delete newContent[key];
        return newContent;
      });
    },
    [setModal, setContent]
  );

  return {
    showingModal,
    content,
    showModal,
    hideModal,
    hideOnClickOverlay,
  } as const;
}

export function useModalDispatch() {
  return useContext(ModalDispatchContext);
}

export function useModalState() {
  return useContext(ModalStateContext);
}
