import { FormEvent, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Spacer } from "../../components/Spacer";
import { TextField } from "../../components/TextField";
import useLoadingOverlay from "../../hooks/useLoadingOverlay";
import { useAuth } from "../../service/auth/AuthProvider";
import { AuthError } from "../../service/auth/error";
import styles from "./LoginPage.module.scss";
import { datadogLogs } from "@datadog/browser-logs";
import { useEffect } from "react";
import { usePharmacy } from "../../hooks/usePharmacy";
import { Footer } from "../../components/Footer";
import { getItem, KEYS, setItem } from "../../service/localStorage";
import { useClientCertificateVerifyDialog } from "../../components/Login/useClientCertificateVerifyDialog";
import { useClientCertificateVerification } from "../../hooks/useClientCertificateVerification";

export const LoginPage: React.VFC = () => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const { signIn, signedIn } = useAuth();
  const { showLoadingOverlay, closeLoadingOverlay } = useLoadingOverlay();
  const history = useHistory();
  const { pharmacy } = usePharmacy();

  const query = new URLSearchParams(useLocation().search);
  const pathname = useLocation().pathname;
  const useDefaultDomain = query.get("useDefaultDomain");

  // デフォルトドメインのフラグ設定.
  useEffect(() => {
    // MEMO:
    // ログイン後も、薬局選択画面やホーム画面でリロードを行うと、この処理が実行されてしまっている。
    // そのため、pathが"/"の場合のみクエリパラメータの値を保存するようにしている。
    if (pathname === "/") {
      if (typeof useDefaultDomain === "string") {
        // クエリパラメータの値をそのまま保存
        setItem(KEYS.USE_DEFAULT_DOMAIN, useDefaultDomain);
      } else {
        // クエリパラメータがない場合は 'false' を文字列として設定
        setItem(KEYS.USE_DEFAULT_DOMAIN, "false");
      }
    }
  }, [useDefaultDomain, pathname]);

  useEffect(() => {
    if (signedIn) {
      if (pharmacy === null) {
        history.replace("/pharmacies");
      } else {
        history.replace("/home");
      }
    }
  }, [signedIn]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!userId || !password) {
      setErrorMsg("ユーザーIDもしくはパスワードが違います");
      return;
    }

    showLoadingOverlay();
    signIn(userId, password)
      .then(() => {
        history.push("/pharmacies");
      })
      .catch((e: AuthError) => {
        datadogLogs.logger.error(JSON.stringify(e));
        if (
          ["UserNotFoundException", "NotAuthorizedException"].includes(e.code)
        ) {
          setErrorMsg("ユーザーIDもしくはパスワードが違います");
        } else {
          setErrorMsg("エラーが発生しました。");
        }
      })
      .finally(() => {
        closeLoadingOverlay();
      });
  };

  const {
    showClientCertificateVerifyDialog,
    hideClientCertificateVerifyDialog,
  } = useClientCertificateVerifyDialog();

  // mablによる自動テストかどうか.
  const isMabl = navigator.webdriver;
  // クライアント証明書検証機能のLDフラグ.
  const clientCertificateVerification = useClientCertificateVerification();
  // クライアント証明書の検証が完了しているかどうか.
  const verified = getItem(KEYS.CERTIFICATE_VERIFIED) === "true";

  console.log(
    `isMabl: ${isMabl}, clientCertificateVerification: ${clientCertificateVerification}, verified: ${verified}`
  );

  useEffect(() => {
    // 以下の条件を満たした場合、クライアント証明書検証ダイアログを表示する.
    // 1. mablによる自動テストではない.
    // 2. クライアント証明書検証機能のLDフラグが有効.
    // 3. クライアント証明書の検証が完了していない.
    if (!isMabl && clientCertificateVerification && !verified) {
      showClientCertificateVerifyDialog({
        onCancel: hideClientCertificateVerifyDialog,
        onClose: hideClientCertificateVerifyDialog,
        onConfirm: hideClientCertificateVerifyDialog,
      });
    }
  }, [
    showClientCertificateVerifyDialog,
    hideClientCertificateVerifyDialog,
    isMabl,
    verified,
  ]);

  return (
    <main className={styles.main}>
      <section className={styles.section}>
        <p className={styles.logo}></p>
        <div className={styles.form_field}>
          {errorMsg && (
            <div>
              <p className="error">{errorMsg}</p>
            </div>
          )}
          <form className={styles.sec_login01_in} onSubmit={onSubmit}>
            <TextField
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              placeholder="ユーザーID"
              maxLength={50}
            />
            <Spacer axis="vertical" size={8} />
            <TextField
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="パスワード"
              maxLength={50}
            />
            <Spacer axis="vertical" size={8} />
            <button className={styles.glb_btn01} type="submit">
              ログイン
            </button>
            <p className={styles.policy}>
              このサービスは患者さまの個人情報を取り扱うため、ログインならびにログイン後の操作は全て記録されています。法人において管理している端末にて適切にご利用ください。
            </p>
          </form>
        </div>
        <Footer isDark />
      </section>
    </main>
  );
};
